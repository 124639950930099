.nav-link {
  margin: 5vw;
  text-decoration: none;
  font-size: 18px;
  color: #6d6e70;
  /* display: inline-block;
  width: 100%; */
}

.navLink {
  line-height: 1.7em;
}

.nav-link:active {
  color: #6d6e70;
}

.nav-link:hover {
  color: #8f8f8f;
  text-decoration: underline;
}

@media only screen and (min-width: 600px) {
  .nav-link {
    margin: 5vw;
    text-decoration: none;
    font-size: 16px;
    color: #6d6e70;
  }
}
