.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0) !important;
}

.ril__builtinButton {
  background-color: #6d6e70 !important;
}

.ril__zoomOutButton {
  background-color: #6d6e70 !important;
}

.ril__outer {
  background: rgba(255, 255, 255, 0.9) !important;
}

.ril__navButtonNext {
  background-color: #6d6e70 !important;
}

.ril__navButtonPrev {
  background-color: #6d6e70 !important;
}

.ril__navButtons {
  padding: 30px 20px !important;
}

.ril__loadingContainer__icon {
  color: #6d6e70 !important;
}

.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 7.5vh;
  /* margin-left: 1.5vh;
  margin-right: 1.5vh; */
}

a {
  color: #6d6e70;
}

/* Create four equal columns that sits next to each other */
.column {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
}

.column img {
  vertical-align: middle;
  width: 100%;
  /* padding: 1.5vh; */
}

.stills:hover {
  opacity: 0.7;
  cursor: pointer;
}

.spacerDiv {
  height: 5vh;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
    padding: 0px;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    padding: 0px;
  }
}
