.aboutBlock {
  text-align: center;
  padding-left: 15vw;
  padding-right: 15vw;
  padding-top: 5vh;

  /* margin-bottom: 15vh; */
}

.inquiries {
  padding-top: 6vh;
  padding-bottom: 6vh;
}

.spacerDiv {
  height: 2vh;
}
