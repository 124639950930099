.logo {
  width: 15vh;
  margin: 5vh;
  margin-top: 7vh;
  align-items: center;
}

@media only screen and (min-width: 600px) {
  .logo {
    width: 10%;
    margin: 7vh;
    margin-bottom: 5vh;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}

.arrowUp {
  background-color: white;
  color: #6d6e70;
  font-size: 30px;
  padding-right: 2vh;
  padding-bottom: 2vh;
  border-radius: 3px;
}
